@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Klein';
  src: url('./assets/klein.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(252, 252, 252);
  color: black;
  scrollbar-color: #A8A8A8;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
}

.bg-body {
  background-color: rgb(34 34 34);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-nav-grey {
  background: rgb(51 51 51);
}

.main-content {
  height: calc(100vh - 74px);

  margin-top: 74px;
}

.athlete-list {
  max-height: calc(100vh - 250px);
}

.card {
  background-color: #444444;
  border-radius: 10px;
}

.table-auto {
  td {
    padding: 0.75rem;
  }
  
  thead {
    tr {
      background: none!important;
      th {
        padding-bottom: 0.75rem;
        font-size: small;
      }
    }
  }

  tr:nth-child(odd) {
    background-color: #333333;
  }

  tr:nth-child(even) {
    background-color: #606060;
  }
}

.table-link-row {
  cursor: pointer;
}

.table-link-row:hover {
  td {
    h3 {
      text-decoration: underline;
    }
  }
}

.tr-no-color {
  background: none!important;
}

.primary_input {
  background: none;
  border: 1px solid #A0A0A0;
  border-radius: 99px;
  color: white;
  padding: 0.5rem 1rem;
}

.force-no-rounded {
  border-radius: 0!important;
}

.scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .card {
    max-height: 80vh;
  }
}

.calendar-marker {
  height: 5px;
  width: 5px;
  background-color: #FFFFFF; /* Color for the marker */
  border-radius: 50%;
  display: inline-block;
}

.react-calendar {
  background: transparent!important;
  border: none!important;
  font-weight: 600;
}

.react-calendar__tile--now {
  background: #555555!important;
  border-radius: 5px;
}

.react-calendar__tile--active {
  background: rgba(83, 204, 26, 0.25)!important;
  color: rgba(83, 204, 26, 1)!important;
  border-radius: 15px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit!important;
}

abbr:where([title]) {
  -webkit-text-decoration: none!important;
  text-decoration: none!important;
}

/* Styles that apply only below md: breakpoint */
@media (max-width: 767px) {
  .hidden-below-md {
    display: none!important ;
  }
}

/* Styles that apply only above md: breakpoint */
@media (min-width: 768px) {
  .sidenav-offset {
    margin-left: 128px
  }
  .sidenav-offset-extended {
    margin-left: 244px
  }
  .hide-above-md {
    display: none;
  }
  .hero-title {
    font-size: 4em;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 0.85em;
    margin-top: 100px;
    margin-left: -15px;
    max-width: 1100px;
  }
  .hero-subtitle {
    font-size: 4em;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.75em;
  }
}

.toggle-switch {
  width: 30px;
  height: 17px;
  background-color: #ccc;
  border-radius: 17px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch.on {
  background-color: #4cd964;
}

.toggle-knob {
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.2s;
}

.toggle-switch.on .toggle-knob {
  left: 15px;
}

.video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-screen-nav {
  height: calc(100vh - 72px);
}

.video-player {
  width: 100%;
  height: auto;
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.end-message {
  text-align: center;
  font-size: 1.5em;
  margin-top: 20px;
}

.bg-hero {
  width: 100%;
  height: 100%;
}

.image-slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.image-slide.active {
  opacity: 1;
}

.hero-overlay {
  background: none;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  z-index: 11;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 125px 125px;
  background-position: -10px -10px;
  z-index: 1;
}

.bg-bfy-green {
  background: #c3d351!important;
}

.border-bfy-green {
  border-color: #c3d351;
}

.text-bfy-green {
  color: #c3d351;
}

.bg-off-black {
  background-color: #333333;
}

.btn {
  font-weight: 700;
  font-size: 1.2rem;
  text-decoration: none;
  z-index: 15;
  cursor: pointer;
}

.btn-primary {
  background: #c3d351;
  color: #000;
}

.btn-primary:hover {
  background: black;
  color: #c3d351;
}

.tile-card {
  background: #333333;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #c3d351
}

.navbar {
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* The blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border-radius: 10px; /* Optional: rounded corners */
  overflow: hidden;
  z-index: 15;
}

.navbar a, i {
  color: white; /* Text color */
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease, color 0.3s ease;
  padding: 15px 30px; /* Padding around the nav items */
  cursor: pointer;
}

.navbar a:hover {
  background: rgba(255, 255, 255, 0.2); /* Slightly darker background on hover */
  color: #000; /* Change text color on hover */
}

.hero-cta-button {
  background: #c3d351;
  color: #000;
  padding: 8px 25px;
  border-radius: 999px;
  font-weight: 900;
  font-size: 1.5em;
  text-decoration: none;
  z-index: 15;
  cursor: pointer;

  .arrow-button {
    background: black;
    color: white;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
  }
}

.hero-cta-button:hover {
  background: black;
  color: #c3d351;

  .arrow-button {
    background: #c3d351;
    color: black;
  }
}

.font-klein {
  font-family: 'Klein', sans-serif;
}

.rounded-full-force {
  border-radius: 999px!important;
}

.places-remaining {
  background: url('../public/placesRemaining.png') no-repeat center center;
  background-size: contain;
  background-position: center;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strava-button {
  background-color: #fc5200;
  border-color: #fc5200;
}

.footer {
  a {
    color: #c3d351;
    font-weight: 600;
    text-decoration: none;
  }
}

.btn-social {
  background-position: 15px center;
  background-repeat: no-repeat;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 600;
  background-size: 20px;
}

.btn-social.facebook {
  background-color: #3b5998;
  color: white;
  background-image: url('../public/socials/facebook.png');
}

.btn-social.google {
  background-color: white;
  border: 1px solid black;
  color: black;
  background-image: url('../public/socials/google.svg');
}

.btn-social.apple {
  background-color: black;
  color: white;
  background-image: url('../public/socials/apple.png');
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 25px;
  background: transparent;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -7px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #b7d900;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #b7d900;
  border-radius: 4px;
}

.slider::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #b7d900;
  border-radius: 4px;
}